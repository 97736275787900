
header.header-section {
    @include media-breakpoint-up(xl) {
        .header-container {
            max-width: 100% !important;
        }
    }
    .input-group-append .btn {
        display: none;
    }
    .search-input {
        height: 61px;
        letter-spacing: 0 !important;
        color: $primary;
        font-size: 43px;
        font-weight: 700;
        line-height: 43px;
    }

    .form-control {
        &::placeholder,
        &:focus {
            letter-spacing: 0 !important;
            color: $primary;
            background-color: #fff;
            border-color: transparent;
            outline: 0;
            font-size: 43px;
            box-shadow: none;
            line-height: 43px;
            font-weight: 700;
            font-family: 'Open Sans';
            
        }
    }
    @include media-breakpoint-down(xl) {
        .search-input {
            font-size: 26px;
            line-height: 26px;
        }

        .form-control {
            &::placeholder,
            &:focus {
                font-size: 26px;
                line-height: 26px;
            }
        }
    }

    .search-toggle {
        &:hover {
            color: $secondary;
        }
    }
    .socials {
        a {
            overflow: hidden;
            width: 20px;
            height: 20px;
            &:hover {
                i {
                    transform: translateY(-100%);
                }
            }
            i {
                width: 20px;
                height: 20px;
                line-height: 20px;
                transition: .3s ease;
            }
        }
    }
    
    border-bottom: 1px solid transparent;
    z-index: 1000;
    top: 0;
    left: 0;
    &.scrolling .container-fluid.py-30px.py-lg-40px.bg-white {
        padding: 15px 15px !important;
    }
    
    .container-fluid.py-30px.py-lg-40px.bg-white {
        transition: .3s ease;   
    }
    
    
    .phone-col {
        svg:not([class*="angle-down"]),
        i:not([class*="angle-down"]) {
            display: none !important;
        }
        ul[id*="rblPhones"] a{
            font-size: 12px !important;
            text-transform: uppercase;
            color: $primary !important;
            letter-spacing: 0.15em;
        }
        .phone-dropdown-menu ul, .address-dropdown-menu ul {
            padding: 0;
            margin: 0;
        }
        .phone-dropdown-menu ul a .row, 
        .address-dropdown-menu ul a .row {
            padding: .5rem .25rem .5rem .5rem;
            position: relative;
        }
        .phone-dropdown-menu ul a, 
        .address-dropdown-menu ul a {
            line-height: 1.4em;
            transition: 0.3s ease;
            opacity: 0;
            visibility: hidden;
            transform: translate3d(0, 1rem, 0);
            display: block;
        }
    }

    nav {
        #desktopMenu>li>a.btn {
            padding-top: 6px !important;
            padding-bottom: 6px !important;
        }

        .icons {
            .dropdown-menu {
                min-width: 15rem;
                border: none;
                box-shadow: 0px 2px 7px rgba(0,0,0,0.2);
                padding: 10px;
                div[id*="container"] a { 
                    color: $primary!important;
                    font-size: 12px!important;
                    text-transform: uppercase;
                    color: $primary!important;
                    letter-spacing: .15em;
                    font-weight: bold;
                    line-height: 1.5;
                    display: block;
                    padding: .5rem .25rem .5rem .5rem;
                    position: relative;
                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        z-index: -1;
                        top: 5%;
                        bottom: 5%;
                        left: 5%;
                        right: 5%;
                        transition: .3s;
                        background-color: rgba(15,20,26,.1)!important;
                        opacity: 0;
                    }
                    &:hover {
                        &:before {
                            right: 0;
                            left: 0;
                            top: 0;
                            bottom: 0;
                            opacity: 1;
                        }
                    }
                }
                div[id*="container"] span { 
                    font-weight: 400;
                    color: #242220!important;
                }
            }
        }
        @include media-breakpoint-down(xl) {
            ul#desktopMenu {
                > li {
                    > a {
                        margin: 0 10px!important;
                        font-size: 14px !important;
                    }
                }
            }
        }
        @include media-breakpoint-up(lg) {
            ul#desktopMenu {
                ul {
                    li {
                        a {
                            position: relative;
                            &::before {
                                content: '';
                                display: block;
                                position: absolute;
                                z-index: -1;
                                top: 5%;
                                bottom: 5%;
                                left: 5%;
                                right: 5%;
                                transition: .3s;
                                background-color: rgba($gray-200,0.15)!important;
                                opacity: 0;
                            }
                            &:hover {
                                &::before {
                                    opacity: 1;
                                    top: 0;
                                    bottom: 0;
                                    left: 0;
                                    right: 0;
                                }
                            }
                            &.current {
                                &::before {
                                    opacity: 1;
                                    top: 0;
                                    bottom: 0;
                                    left: 0;
                                    right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }

        
        ul#desktopMenu > li > a {
            position: relative;
        }

        ul#desktopMenu > li > a:not(.btn):after{
            -ms-transition: -webkit-transform .3s ease-out,border-color .3s ease-out;
            -webkit-transition: -webkit-transform .3s ease-out, border-color .3s ease-out;
            transition: transform .3s ease-out,border-color .3s ease-out;
            position: absolute;
            display: block;
            bottom: -6px;
            left: 0;
            width: 100%;
            -ms-transform: scaleX(0);
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            border-top: 2px solid #6b6f72;
            content: '';
            padding-bottom: inherit;
        }

        ul#desktopMenu > li > a.current:after,
        ul#desktopMenu > li > a.highlighted:after,
        ul#desktopMenu > li > a:hover:after {
            backface-visibility: hidden;
            -ms-transform: scaleX(1);
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }

    }
    
.phone-dropdown-menu ul a, 
.address-dropdown-menu ul a {
    .row {
        &:before {
            content: '';
            display: block;
            position: absolute;
            z-index: -1;
            top: 5%;
            bottom: 5%;
            left: 5%;
            right: 5%;
            transition: 0.3s ease;
            background-color: rgba(15,20,26,0.1) !important;
            opacity: 0;
        }
    }
    &:hover {
        .row {
            &:before {
                right: 0;
                left: 0;
                top: 0;
                bottom: 0;
                opacity: 1;
            }
        }
    }
}
.phone-dropdown-menu,
.address-dropdown-menu {
    transition: opacity 0.2s ease, visibility 0.2s ease, margin-top 0.2s ease;
    display: block;
    opacity: 0;
    visibility: hidden;
    padding: 1rem * 0.5;
    border: none;
    background: transparent;
    margin-top: -1.5rem !important;
    max-width: 100% !important;
    min-width: 16rem !important;
    margin-right: -5px;
    box-shadow: none !important;

    span[class*="col"] {
        color: #242220 !important;
    }
    span.phone-number {
        color: $primary !important;
    }

  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: 5%;
    bottom: 25%;
    left: 25%;
    right: 25%;
    transition: 0.3s ease;
    border-radius: $border-radius;
    background-color: #f9f9f9!important;
    box-shadow: $box-shadow;
    opacity: 0;
  }

  &.show {
    opacity: 1;
    visibility: visible;

    &:before {
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      opacity: 1;
    }

    ul {
      @for $i from 1 to 10 {
        a:nth-child(#{$i}) {
          opacity: 1;
          visibility: visible;
          transform: translate3d(0, 0, 0);
          transition-delay: 0.2 + ($i * 0.025s);
        }
      }
    }
  }
}






}


.search-ui {
    transition: 0.4s ease;
    opacity: 0;
    visibility: hidden;
}

body.search-show {
    .search-ui {
        opacity: 1;
        visibility: visible;
    }
    .header-interior-wrap {
        opacity: 0;
        visibility: hidden;
    }
}
